#shadow-left button {
  display: inline-block;
  /* z-index: 100;   */
  /* 擋到了boom */
}

.wrapper-table-bet {
  @apply w-full;
  @apply text-sm text-white;
  @apply bg-[#222332];
}

.table-bet {
  @apply w-full;
  @apply text-[14px];
  @apply bg-[#222332];
}

.table-bet .win {
  @apply text-win-lighter;
}
.table-bet .lose {
  @apply text-lose;
}

.table-bet .win-bg-play {
  animation: winBg 0.3s;
  animation-iteration-count: 1;
  animation-play-state: running;
}
.table-bet .lose-bg-play {
  animation: loseBg 0.3s;
  animation-iteration-count: 1;
  animation-play-state: running;
}
@keyframes winBg {
  0% {
    background: unset;
  }
  70% {
    background: #2d482f;
  }
  100% {
    background: unset;
  }
}
@keyframes loseBg {
  0% {
    background: unset;
  }
  70% {
    background: #5b2626;
  }
  100% {
    background: unset;
  }
}

a .table-bet .username {
  width: calc(25% - 20px);
}

/* thead */
.table-bet thead tr th {
  @apply border-b-2 border-[#4d4d60];
}
/* thead */
.table-bet thead tr.filled th {
  @apply border-0;
  @apply bg-[#3D4360]  text-white;
}

/* tbody */
.table-bet tbody.title tr th {
  @apply bg-[#222332] text-[#71778E];
  @apply text-xs;
}
/* tfoot */
.table-bet tfoot tr th {
  @apply border-t-2 border-[#4d4d60];
  color: #71778e;
}

/* align */
.table-bet tr th,
.table-bet tr td {
  @apply text-center py-[0.5rem] text-main;
}
.table-bet tr th:first-child,
.table-bet tr td:first-child {
  @apply text-left pl-4;
}
.table-bet tr th:last-child,
.table-bet tr td:last-child {
  @apply text-right pr-4;
}

/* bg-color*/
.table-bet tbody tr:nth-child(odd) {
  @apply bg-[#303144];
}
.table-bet tbody tr:nth-child(even) {
  @apply bg-[#25263B];
}

/* username width*/
.w-username {
  width: 80px;
}
@media (max-width: 376px) {
  .w-username {
    width: 45px;
  }
}
@media (max-width: 300px) {
  .w-username {
    width: 30px;
  }
}
