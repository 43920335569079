.numpad {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* position: -ms-device-fixed; */
  z-index: 10000;
  top: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  height: calc(var(--vh, 1vh) * 100);
}
.numpadBtn:hover {
  background-color: rgb(44 46 79);
}
.numpadBtn:active {
  background-color: rgb(53 55 102);
}
.numpadBackdrop {
  width: 100%;
  height: 100%;
  z-index: 2000;
  @apply bg-black/50;
}

.numpadContent {
  position: absolute;
  bottom: 0;
  width: 100%;
  @apply bg-main-100;
}

.numpadRowLabel {
  @apply flex flex-row justify-start items-center;
  @apply bg-black text-white px-4;
  font-size: 1rem;
  line-height: 40px;
  height: 40px;
}

/* 結果 */
.numpadRowDone {
  @apply flex flex-row justify-between;
  @apply bg-[#0d0e1a];
  font-size: 20px;
  line-height: 50px;
  height: 50px;
}
.numResult {
  @apply px-4 text-win-lighter;
  max-width: 66%;
  overflow: hidden;
  line-height: 50px;
}
.numpadBtnDone {
  @apply px-2 bg-btn-yellow rounded;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 33.33%;
}
.numpadBtnDone {
  background: #f9d865;
  color: #000;
}
.numpadBtnDone:hover {
  background: #d2b655;
}
.numpadBtnDone:active {
  background: #ffaf65;
}

/* 數字區 */
.numpadRowNum {
  @apply flex flex-row;
}
.numpadBtn {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  width: 33.3%;
  @apply bg-main-100 text-white;
}
