/* ROBOTO for MUI UI */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-control {
    @apply border-gray-300 border p-1 rounded-sm;
  }

  .nav-button {
    @apply text-sm border bg-blue-100;
    @apply p-2 m-2;
  }

  .layout-chart {
    @apply w-full;
    @apply min-h-[280px] md:min-h-[280px]; /* webview 需要 */
  }
  .layout-chart canvas {
    @apply w-full h-full;
  }
  .layout-rocket {
    width: calc(100%);
    height: calc(100%);
  }
  .layout-rocket canvas {
    width: 100%;
    height: 100%;
  }

  /* 消除input點擊的亮框 */
  input:focus-visible {
    outline: none;
  }

  /* input有包覆%或X */
  .form-input-wrapper {
    @apply text-sm rounded bg-[#242535] text-white border border-[#3D3E59];
  }
  .form-input-wrapper .form-input {
    @apply text-sm bg-[#242535] text-white border-0;
  }

  .form-input-wrapper .form-input-text {
    @apply p-2;
  }
  .form-input {
    @apply text-sm rounded p-2 bg-[#242535] text-white border border-[#3D3E59];
  }

  .btn {
    @apply text-white p-2;
  }
  .btn[disabled] {
    @apply opacity-50;
  }

  .btn-sub {
    @apply bg-[#343648];
    @apply text-white text-sm;
  }
  .btn-sub[disabled] {
    @apply opacity-50;
  }
  .btn-calc {
    @apply w-[34px] h-[38px];
    @apply border border-y-[#3D3E59] border-l-0  border-r-[#25263C]; /*上下左右*/
    @apply text-[12px] text-white;
  }

  .btn-calc:hover {
    @apply bg-[#4f493e] text-[#f9d865];
  }
  .btn-calc:active {
    @apply bg-[#4d506b] text-white;
  }
  .btn-calc:last-child {
    /*最後一個右邊收邊*/
    @apply border-r-[#3D3E59];
  }

  .btn-actions:hover {
    @apply bg-[#4f493e] text-[#f9d865];
  }
  .btn-actions:active {
    @apply bg-[#4d506b] text-white;
  }

  .btn-solid {
    @apply rounded text-white border border-gray-700 text-sm p-2;
  }

  .btn-radio {
    @apply py-2 text-white border flex-1;
  }

  /* crashout btn */
  .btn-out.is-active {
    @apply bg-[#4f493e] text-[#f9d865];
  }
}

@import './table-bet.css';
@import './modal.css';
@import './pages.css';
@import './mui-pagination.css';

html,
body {
  font-family: 'Roboto' !important;
  background: #4d4d60;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent; /*fastClick*/
  overscroll-behavior-y: contain !important; /* 禁用默认的下拉刷新和边界效果,但是依然可以进行滑动导航 */
}

html {
  background-color: #25263c;
}

.scroll-lock {
  width: 100%;
  position: fixed !important;
  overflow: hidden !important;
}

small {
  font-size: 60%;
}

.custom-scroll-transparent::-webkit-scrollbar {
  background-color: transparent;
  height: 0;
  width: 0;
}
.custom-scroll-transparent {
  scrollbar-width: none; /*firefox*/
}

.z-higher-than-drop {
  position: relative;
  z-index: 11;
  /*高過form的黑色drop*/
}

@keyframes in-out {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

/* animation */
.duration-faster {
  -webkit-animation-duration: 0.3s !important;
  animation-duration: 0.3s !important;
  -webkit-animation-fill-mode: both !important;
  animation-fill-mode: both !important;
}

#drop-bg {
  @apply fixed bg-[#25263B] w-full;
}
.remove-drop-bg #drop-bg {
  @apply bg-transparent;
}

/* btn */
input:disabled,
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-bet {
  background: #f9d865;
  color: #000;
}
.btn-bet:hover {
  background: #d2b655;
}
.btn-bet:active {
  background: #ffaf65;
}

.btn-profit {
  background: #75a348;
  color: #fff;
}
.btn-profit:hover {
  background: #486c25;
}
.btn-profit:active {
  background: #8dc655;
}

.btn-stop {
  background: #cc5548;
  color: #fff;
}
.btn-stop:hover {
  background: #a03529;
}
.btn-stop:active {
  background: #ff6f60;
}

/* Input placeholder */
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5f6173;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #5f6173;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #5f6173;
}

/* doc */
.doc-ol {
  @apply ml-4;
  font-size: 14px;
}
.doc-ol li {
  list-style: decimal;
  @apply mb-4;
  @apply text-[#B0B0B0];
}
